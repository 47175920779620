import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Authentication from "./Authentication.js";
import Authorization from "./Authorization.js";


/****Layouts*****/
const DataSecurityLayout  = lazy(() => import("../layouts/DataSecurityLayout.js"));
const CurriculumHomeLayout = lazy(() => import("../layouts/CurriculumHomeLayout.js"));
const BlogsHomeLayout = lazy(() => import("../layouts/BlogsHomeLayout.js"));
const BlogDetailLayout = lazy(() => import("../layouts/BlogDetailLayout.js"));
const ProfileLayout = lazy(() => import("../layouts/ProfileLayout.js"));
const AdmiHomeLayout = lazy(() => import("../layouts/AdminHomeLayout.js"));
const AdminCurriculumDetailsLayout = lazy(() => import("../layouts/AdminCurriculumDetailsLayout.js"));
const AdminUsersLayout = lazy(() => import("../layouts/AdminUsersLayout.js"));


/*****Routes******/

/**Pages*/
const LoginView = lazy(() => import("../views/dss/login/LoginView.js"));
const RegisterView = lazy(() => import("../views/dss/register/RegisterView.js"));
const RegisterSuccessView = lazy(() => import("../views/dss/register/RegisterSuccessView.js"));
const ProfileActivationView = lazy(() => import("../views/dss/register/ProfileActivationView.js"));
const ForgotUserNameView = lazy(() => import("../views/dss/forgotUserName/ForgotUserNameView.js"));
const ForgotPasswordView = lazy(() => import("../views/dss/forgotPassword/ForgotPasswordView.js"));
const UnauthorizedView = lazy(() => import("../views/dss/UnauthorizedView.js"));
const CompleteProfileView = lazy(() => import("../views/dss/completeProfile/CompleteProfileView.js"));

const CurriculumsView = lazy(() => import("../views/user/curriculums/CurriculumsView.js"));
const CurriculumDetailsView = lazy(() => import("../views/user/curriculums/details/CurriculumDetailsUserView.js"));
const FormSubmitView = lazy(() => import("../views/FormSubmitView.js"));
const FormViewerView = lazy(() => import("../views/FormViewerView.js"));

const BlogsHomeView  = lazy(() => import("../views/user/blogs/BlogsHomeView.js"));
const BlogDetailsView  = lazy(() => import("../views/user/blogs/details/BlogDetailsView.js"));

const ProfileHomeView = lazy(() => import("../views/user/profile/ProfileHomeView.js"));

const AdminCurriculumView = lazy(() => import("../views/admin/curriculum/CurriculumAdminView.js"));
const AdminCurriculumDetailsView = lazy(() => import("../views/admin/curriculum/details/CurriculumDetailsView.js"));
const AdminCurriculumSubmissionView = lazy(() => import("../views/admin/curriculum/details/submission/CurriculumSubmissionView.js"));
const AdminUsersView = lazy(() => import("../views/admin/users/AdminUsersView.js"));
const AdminBlogsView = lazy(() => import("../views/admin/blogs/AdminBlogsView.js"));
const AdminSettingsView = lazy(() => import("../views/admin/settings/AdminSettingsView.js"));

const ThemeRoutes = [
  {
    path: "/",
    element: <BlogsHomeLayout />,
    children: [
      { path: "", exact: true, element: <Navigate to="/blogs" replace />},
      { path: "/blogs", exact: true, element: <BlogsHomeView /> },
    ],
  },
  {
    path: "/blog",
    element: <BlogDetailLayout />,
    children: [
      { path: "/blog/*", exact: true, element: <BlogDetailsView />},
    ],
  },
  {
    path: "/curriculum",
    element: <CurriculumHomeLayout />,
    children: [
     /* { path: "", exact: true, element: <BlogsView /> },*/
      { path: "", exact: true, element: <CurriculumsView /> },
      { path: "/curriculum/*", exact: true, element: <CurriculumDetailsView/> },
      { path: "/curriculum/formSubmit/*", exact: true, element: <FormSubmitView /> },
      { path: "/curriculum/formView/*", exact: true, element: <FormViewerView /> }
    ],
  },
  {
    path: "/dss",
    element: <DataSecurityLayout />,
    children: [
      { path: "/dss/login", exact: true, element:<LoginView />},
      { path: "/dss/forgot-username", exact: true, element:<ForgotUserNameView />},
      { path: "/dss/forgot-password", exact: true, element:<ForgotPasswordView />},
      { path: "/dss/register", exact: true, element:<RegisterView />},
      { path: "/dss/register/success", exact: true, element:<RegisterSuccessView />},
      { path: "/dss/profile/activate", exact: true, element:<ProfileActivationView />},
      { path: "/dss/forbidden", exact: true, element:<UnauthorizedView/>},
      { path: "/dss/completeProfile", exact: true, element:<CompleteProfileView/>}
    ]
  },
  {
    path: "/user",
    element: <Authentication><ProfileLayout /></Authentication>,
    children: [
      { path: "profile", exact: true, element: <Authorization><ProfileHomeView viewId="ProfileView" /></Authorization>},
    ]
  },
  {
    path: "/admin",
    element: <Authentication><AdmiHomeLayout /></Authentication>,
    children: [
      { path: "", exact: true,  element: <Navigate to="curriculums" replace />},
      { path: "curriculums", exact: true, element:<Authorization><AdminCurriculumView viewId="AdminCurriculumView"/></Authorization>},
      { path: "blogs", exact: true, element:<Authorization><AdminBlogsView viewId="AdminBlogsView"/></Authorization>},
      { path: "settings", exact: true, element:<Authorization><AdminSettingsView viewId="AdminSettingsView"/></Authorization>},
    ]
  },
  {
    path: "/admin/curriculum",
    element: <Authentication><AdminCurriculumDetailsLayout/></Authentication>,
    children: [
      { path: "", exact: true,  element: <Navigate to="*" replace />},
      { path: "*", exact: true, element: <Authorization><AdminCurriculumDetailsView viewId="AdminCurriculumDetailsView"/></Authorization> },
      { path: "/admin/curriculum/sub/*", exact: true,  element: <Authorization><AdminCurriculumSubmissionView viewId="FormView" /></Authorization>},
    ]
  },
  {
    path: "/admin/users",
    element: <Authentication><AdminUsersLayout/></Authentication>,
    children: [
      { path: "", exact: true, element:<Authorization><AdminUsersView viewId="UsersView"/></Authorization>},
      { path: "/admin/users/*", exact: true, element:<Authorization><AdminUsersView viewId="UsersView"/></Authorization>}
    ]
  }
  
];

export default ThemeRoutes;
