import config from "./config";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { ShortCrypt } from "short-crypt";

const shortCryptKey = "JwOwwUuFqcA9vEuZiBdT0w==";
const shortCrypt = new ShortCrypt(shortCryptKey);
export const encrypt = (plainText) => {
    let cipher = shortCrypt.encryptToURLComponent(plainText.toString());
    return cipher;
}
export const decrypt = (cipher) => {
    let decrypted = shortCrypt.decryptURLComponent(cipher);
    if (decrypted) {
        return new TextDecoder().decode(decrypted.buffer);
    }
    return null;

}

export const remoteEndPoint = function () {
   // return process.env.NODE_ENV === "development" ? config.SERVICE_END_POINT_DEV : config.SERVICE_END_POINT_PROD;
   
   return process.env.NODE_ENV === "development" ? "http://"+window.location.hostname : config.SERVICE_END_POINT_PROD;
}
const buildKey = (key)=>{
    let prefix =  "WLG_";
    if(process.env.NODE_ENV === "development"){
        prefix += "DEV_"
    }
    return prefix + key;
}
export const localdb = {
    put: (k, v) => {
        localStorage.setItem(buildKey(k), v)
    },
    get: (k) => {
        return localStorage.getItem(buildKey(k));
    },
    remove: (k) => {
        localStorage.removeItem(buildKey(k));
    }
}
export const TOKEN_HEADER_KEY = "cu-access-token";

export const getAuthHeader = () => {
    var authHeader = {}
    authHeader[TOKEN_HEADER_KEY] = localdb.get(TOKEN_HEADER_KEY);
    return authHeader;
}

export const getAuthToken = () => {
    return localdb.get(TOKEN_HEADER_KEY);
}

export const uriAPI = () => {
    return remoteEndPoint() + getApiEndPoint();
}

const getApiEndPoint = () => {
    return process.env.NODE_ENV === "development" ? config.SERVICE_API_DEV : config.SERVICE_API_PROD;
}

const getMediaDownloadEndPoint = () => {
    return process.env.NODE_ENV === "development" ? config.MEDIA_API_DOWNLOAD_DEV : config.MEDIA_API_DOWNLOAD;
}

export const uriMedia = () => {
    return remoteEndPoint() + getUriEndPoint();
}

export const uriDownloadMedia = () => {
    return remoteEndPoint() + getMediaDownloadEndPoint();
}

const getUriEndPoint = () => {
    return process.env.NODE_ENV === "development" ? config.MEDIA_API_DEV : config.MEDIA_API_V1;
}

export const validateField = (layoutField, field) => {
    switch (layoutField.inputType.toUpperCase()) {
        case 'TEXT':
            return null;

        case 'TEXTAREA':
            return null;

        case 'DOCUMENT':
            if (![
                'application/pdf',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                'text/rtf',
                'text/plain',
                'application/x-iwork-pages-sffpages',
                'application/x-iwork-numbers-sffnumbers',
                'application/x-iwork-keynote-sffkey'
            ].includes(field.type)) {
                return "Invalid format";
            }
            return null;
        case 'IMAGE':
            if (![
                'image/jpeg',
                'image/png',
                'image/gif',
                'image/bmp',
            ].includes(field.type)) {
                return "Invalid format";
            }
            return null;
        case 'AUDIO':
            if (![
                'video/ogg',
                'audio/mpeg',
                'audio/wav',
                'audio/aac',
                'audio/x-m4a',
            ].includes(field.type)) {
                return "Invalid format";
            }
            return null;
        case 'VIDEO':
            if (![
                'video/ogg',
                'video/mp4',
                'video/quicktime',
                'video/x-ms-wmv',
                'video/avi',
                'video/mpeg',
                'video/mpeg2'
            ].includes(field.type)) {
                return "Invalid format";
            }
            return null;

        case 'RADIO':
        case 'CHECK':
            return null;

        default:
            return "Invalid format";

    }

}


/**
 * 
 * @param {*} expiryDateStr =>Curriculum target date
 * @param {*} targetDateStr =>Lesson target date
 * @param {*} submittedDateStr =>Lesson submitted date
 * @returns {text, color, show}
 */
export const ribbonProps = (expiryDateStr, targetDateStr, submittedDateStr)=>{
    let props = {text:"", color:"", show: false};

    let expiryDate = dateUtil.formatDate(expiryDateStr);
    let targetDate = dateUtil.formatDate(targetDateStr);
    let submittedDate = dateUtil.formatDate(submittedDateStr);

    if(!expiryDate || !targetDate){
        return props;
    }

    //case : Expired and not submitted
    if(!submittedDate && dateUtil.isPastDate(expiryDate)){
        props.text = "Expired";
        props.color = "grey";
        props.show = true;
        return props;
    }
    //cas : not submitted
    if(!submittedDate){
        props.text = "Pending";
        props.color = "pink";
        props.show = true;
        return props;
    }
    //case : late submission
    if(submittedDate && submittedDate > targetDate){
        props.text = "Delayed";
        props.color = "volcano";
        props.show = true;
        return props;
    }else{//case On time
        props.text = "On Time";
        props.color = "green";
        props.show = true;
        return props;
    }
}

export const dateUtil = {

    isPastDate : (dateIn)=>{
        let dateToday = dateUtil.formatDate(new Date());
        return dateIn && dateUtil.daysBetween(dateIn, dateToday) > 0
    },
    formatDate : function (date, format='DD MMM, YYYY HH:mm') {
        if (!date) {
            return null;
        }
        let strDate = moment(date).format(format);
        if (strDate === "Invalid date") {
            return null;
        }
        return strDate;
    },
    
    formatDateOnly : function (date) {
        if (!date) {
            return null;
        }
        let momentDate = moment(date).format('LL');
        if (momentDate === "Invalid date") {
            return null;
        }
        return momentDate;
    },
    daysBetween : (targetDate, submittedDate) => {
        if (!targetDate || !submittedDate) {
            return;
        }
        return moment(submittedDate).diff(moment(targetDate), 'days');
    },
    fromNow : (date)=>{
        if (!date) {
            return null;
        }
        let serverUtcDate = moment.utc(date).toDate();
        let localDate = moment(serverUtcDate).local();
        let timeNow = localDate.fromNow();
         return timeNow;
    }
    
   

}

export const statusCode = {
    OK : 200,
    CREATED : 201,
    NO_CONTENT : 204,
    ALREADY_COMPLETED : 208,
    BAD_REQUEST: 400,
    AUTHENTICATION_REQUIRED: 401,
    FORBIDDEN : 403,
    NOT_FOUND : 404,
    CONFLICT : 409,
    PRECONDITION_REQUIRED : 428,
    INTERNAL_SERVER_ERROR : 500
}

export const authUtil = {
    getLink:(links, linkName) => {
        if(!links || !Array.isArray(links) || !linkName){
            return null;
        }
       let link =  links.filter((link) => link.name == linkName)[0];
       return link;
    },
    hasLink:(links, linkName) => {
        if(!links || !Array.isArray(links) || !linkName){
            return false;
        }
        let resLinks =  links.filter((link) => link.name == linkName)
        return resLinks.length > 0;
    }
}

export const paginatorUtil = {
    getParams :(paginatorConfig)=>{
        if(!paginatorConfig || !paginatorConfig.pageNumber){
            return "?pageNumber=1&pageSize=5";
        }
        let queryStr = "?pageNumber="+paginatorConfig.pageNumber;
        if(paginatorConfig.pageSize){
            queryStr +="&pageSize="+paginatorConfig.pageSize;
        }
        return queryStr;
    },
    getConfig: (config)=>{
        if(!config){
            return {pageNumber:"1",pageSize:"5"};
        }
        return config;

    }

}

export const themeUtil = {
    updateTheme :(themeConfig)=>{
        if (themeConfig) {
            if(themeConfig.COLOR_PRIMARY){
                document.body.style.setProperty("--primary-color", themeConfig.COLOR_PRIMARY);
                document.body.style.setProperty("--primary-color-alert-success", themeConfig.COLOR_PRIMARY.concat('10'));
            }
            if(themeConfig.COLOR_SECONDARY){
                document.body.style.setProperty("--secondary-color", themeConfig.COLOR_SECONDARY);
            }
            if(themeConfig.COLOR_HEADER){
                document.body.style.setProperty("--header-color", themeConfig.COLOR_HEADER);
            }
        }
    }
}

